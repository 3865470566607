









































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import OrderService from '@/services/OrderService'
import { namespace } from 'vuex-class'
import moment from 'moment'
import StoreInfoService from '@/services/StoreInfoService'
import ProductService from '@/services/ProductService'
import PaymentSettingService from '@/services/PaymentSettingService'
import CreditCardVerificationService from '@/services/CreditCardVerificationService'
import Constants from '../constants'
const CartProductStore = namespace('CartProductStore')
const ModeBuyProduct = namespace('ModeBuyProduct')

@Component({
  components: {
    LayoutCompact
  }
})
export default class ProductList extends Vue {
  public optionMonths: any = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
  ]
  public optionYears: any = []
  public deliveryDateNormal: string = ''
  public deliveryDatePeriodic: string = ''
  public cartTotalPrice: number = 0
  public cartTotalPriceNoTax: number = 0
  public cartTotalQuantity: number = 0
  public noticeProductStoke: boolean = false
  public card_number: any = ''
  public expire_year: any = ''
  public expire_month: any = ''
  public cvc: any = ''
  public first_name: any = ''
  public last_name: any = ''
  public tokenPayment: any = ''
  public check_card_payment: any = 0
  public cartTax8: any = 0
  public cartTax10: any = 0
  public delivery_tax: any = 0
  public cartTaxFree: any = 0
  public cartNotTaxFree: any = 0
  public totalCartTax8: any = 0
  public totalCartTax10: any = 0
  public delivery_fee_without_tax: any = 0
  public totalInternalCartTax8: any = 0
  public totalInternalCartTax10: any = 0
  public cartInternalTax8: any = 0
  public cartInternalTax10: any = 0
  public messageProductSoldOut: boolean = false
  public messageNoProduct: boolean = false
  public messageNoRegulary: boolean = false
  public delivery_service_id_normal: any = ''
  public messageOrderNormalValidate: string = ''
  public message: boolean = false
  public messageTimeZone: boolean = false
  public paymentInfoDoesNotExist: boolean = false
  public deliveryServiceName: string = ''
  public estimateDeliveryTimePeriodic: string = ''
  public estimateDeliveryTimeNormal: string = ''
  public customerName: string = ''
  public zipCode: string = ''
  public addressShipping: string = ''
  public phoneNo: string = ''
  public moment: any = moment(new Date()).format('yyyy-MM-DD')
  public msg: string = ''
  public isDisableInfoCard: boolean = true
  public isDisablePayorder: boolean = false
  public isDisableOrder: boolean = false
  public minimum_amount: number = 0
  public frozenName: string = ''
  public frozenPrice: any = 0
  public coolingName: string = ''
  public coolingPrice: any = 0
  public normalTemName: string = ''
  public normalTemPrice: any = 0
  public discountRate: any = 0
  public deliveryFee: any = 0
  public shippingFee: any = 0
  public consumptionTax: any = ''
  public mesCannotDeleteOldCardInfo: boolean = false
  public invalid_product_in_cart = []

  private cartTotalPriceTax: any = 0
  private flag: boolean = false
  private paygentTokenStr: string = ''
  private fingerprint: string = ''
  private cartProductList = []
  private productsSoldOut: any = []
  private temperatureControls: any = []
  private memo: string = ''
  private regularDeliveryCycle: string = ''
  private regular_type: string = ''
  private normalOrder: any = {
    order_date: '',
    delivery_date: '',
    delivery_service_id: '',
    products: []
  }
  private periodicOrder: any = {
    regular_type: '',
    delivery_date: '',
    delivery_service_id: '',
    products: []
  }
  private stockQuantity: any = 0
  @CartProductStore.Getter
  private getCart!: any
  @CartProductStore.Action
  private removeFromCart!: (data: any) => any
  @CartProductStore.Action
  private removeAllCart!: () => any
  @CartProductStore.Action
  private updateFromCart!: (data: any) => any
  @ModeBuyProduct.Getter
  private getModeBuyProduct!: number

  @Watch('getCart')
  updateCart() {
    this.handleCalcOverviewCart()
    this.hanldeGetCartProductList()
  }

  @Watch('getModeBuyProduct')
  watchModeBuyProduct() {
    this.hanldeGetCartProductList()
  }

  @Watch('cvc')
  changeCvc() {
    this.flag = false
  }

  @Watch('card_number')
  changeCreditCard() {
    this.isDisableInfoCard = false
  }

  async created() {
    let year: any = parseInt(
      new Date()
        .getFullYear()
        .toString()
        .substr(2, 2)
    )
    for (let i = 0; i < 10; i++) {
      this.optionYears.push(year + i)
    }
    await CreditCardVerificationService.getCreditCard().then(
      (response: any) => {
        if (response.data.items.length !== 0) {
          let items = response.data.items.data
          if (items.card_number !== '') {
            this.card_number = 'XXXX-XXXX-XXXX-' + items.card_number
          }
          if (items.expire_month !== '') {
            this.expire_month = items.expire_month
          }
          if (items.expire_year !== '') {
            this.expire_year = items.expire_year
          }
          if (items.user_first_name !== '') {
            this.first_name = items.user_first_name
          }
          if (items.user_last_name !== '') {
            this.last_name = items.user_last_name
          }
          this.check_card_payment = 1
          this.flag = true
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
        if (error.response.status === 500) {
          if (
            error.response.data.message[0] === 'CAN_NOT_GET_CARD_INFORMATION'
          ) {
            this.flag = false
          }
        }
      }
    )
    this.isDisableInfoCard = true
    this.hanldeGetCartProductList()
    this.handleCalcOverviewCart()
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user') || '').user
      this.customerName = user.name
      this.zipCode = user.profile.zip_code
      if (user.profile.apartment_no === null) {
        user.profile.apartment_no = ''
      }
      if (user.profile.prefecture === null) {
        user.profile.prefecture = ''
      }
      if (user.profile.city === null) {
        user.profile.city = ''
      }
      if (user.profile.address === null) {
        user.profile.address = ''
      }
      this.addressShipping =
        user.profile.prefecture +
        ' ' +
        user.profile.city +
        ' ' +
        user.profile.address +
        ' ' +
        user.profile.apartment_no
      this.phoneNo = user.profile.phone_no
    }
    let mode_purchases = JSON.parse(
      localStorage.getItem('mode_purchases') || ''
    )
    if (Number(mode_purchases) === 1) {
      if (localStorage.getItem('delivery_service_id_normal')) {
        this.delivery_service_id_normal =
          localStorage.getItem('delivery_service_id_normal') || ''
        await StoreInfoService.getInfoDeliveryTimezone(
          this.delivery_service_id_normal
        ).then(response => {
          if (response) {
            let items: any = response.data.items
            this.deliveryServiceName = items.service_name
            this.estimateDeliveryTimeNormal =
              items.start_delivery_zone + '〜' + items.end_delivery_zone
          }
        })
      }

      if (localStorage.getItem('delivery_date_normal')) {
        this.deliveryDateNormal =
          localStorage.getItem('delivery_date_normal') || ''
      }
    }
    if (Number(mode_purchases) === 2) {
      if (localStorage.getItem('delivery_service_id_periodic')) {
        this.delivery_service_id_normal =
          localStorage.getItem('delivery_service_id_periodic') || ''
        StoreInfoService.getInfoDeliveryTimezone(
          this.delivery_service_id_normal
        ).then(response => {
          if (response) {
            let items: any = response.data.items
            this.deliveryServiceName = items.service_name
            this.estimateDeliveryTimePeriodic =
              items.start_delivery_zone + '〜' + items.end_delivery_zone
          }
        })
      }
      if (localStorage.getItem('delivery_date_periodic')) {
        this.deliveryDatePeriodic =
          localStorage.getItem('delivery_date_periodic') || ''
      }
      if (localStorage.getItem('regular_type')) {
        this.regular_type = localStorage.getItem('regular_type') || ''
        if (this.regular_type === '1') {
          this.regularDeliveryCycle = '1週間ごと'
        }
        if (this.regular_type === '2') {
          this.regularDeliveryCycle = '2週間ごと'
        }
        if (this.regular_type === '3') {
          this.regularDeliveryCycle = '1ヶ月ごと'
        }
      }
    }
  }

  public async handleCalcOverviewCart() {
    this.cartTotalPrice = 0
    this.cartTotalQuantity = 0
    this.cartTax8 = 0
    this.cartTax10 = 0
    this.cartTotalPriceNoTax = 0
    this.cartTotalPriceTax = 0
    this.totalCartTax8 = 0
    this.totalCartTax10 = 0
    this.cartTaxFree = 0
    this.cartInternalTax8 = 0
    this.cartInternalTax10 = 0
    this.totalInternalCartTax8 = 0
    this.totalInternalCartTax10 = 0
    this.temperatureControls = []
    this.frozenName = ''
    this.frozenPrice = 0
    this.coolingName = ''
    this.coolingPrice = 0
    this.normalTemName = ''
    this.normalTemPrice = 0
    this.deliveryFee = 0
    this.discountRate = 0
    this.shippingFee = 0
    this.delivery_fee_without_tax = 0
    this.delivery_tax = 0
    this.hanldeGetCartProductList()
    if (this.cartProductList.length !== 0) {
      await PaymentSettingService.getPaymentSetting().then(
        response => {
          if (response) {
            let items: any = response.data.items
            this.discountRate = items.tax_setting
            this.deliveryFee = items.delivery_fee
            this.consumptionTax = items.consumption_tax
          }
        },
        (error: any) => {
          if (error) {
            if (error.response.status === 429) {
              this.manyRequest()
            }
          }
        }
      )
      if (this.getModeBuyProduct === 1) {
        console.log('cartProductList', this.cartProductList);
        
        this.cartProductList.forEach((item: any) => {
          if (item.mode === 1) {
            this.reloadData(item)
            // total Order Tax
            this.cartTotalPriceTax =
              this.cartTotalPriceNoTax +
              this.cartTax8 +
              this.cartTax10 +
              this.cartInternalTax8 +
              this.cartInternalTax10
          }
        })
      }
      if (this.getModeBuyProduct === 2) {
        this.cartProductList.forEach((item: any) => {
          if (item.mode === 2) {
            this.reloadData(item)
            this.cartTotalPriceTax =
              this.cartTotalPriceNoTax +
              this.cartTax8 +
              this.cartTax10 +
              this.cartInternalTax8 +
              this.cartInternalTax10
          }
        })
      }
      // check minium_amount
      let tour_mode = localStorage.getItem('tour_mode') ? true : false
      if (!tour_mode) {
        await StoreInfoService.getStoreInfo().then(
          response => {
            if (response) {
              let items: any = response.data.items
              this.minimum_amount = items.minimum_amount
              // Stage 1: Check total product amount greater than equal stage 1 price
              if (
                items.shopping_price_with_shipping_discount &&
                this.cartTotalPriceNoTax >=
                  items.shopping_price_with_shipping_discount
              ) {
                // If pass, Stage 2: check total product amount greater than equal stage 2 price
                if (items.shopping_price_with_shipping_discount_stage2 && items.shopping_price_with_shipping_discount_stage2 <= this.cartTotalPriceNoTax) {
                  this.shippingFee = Number(items.shipping_free_after_discount_stage2)
                } else {
                  this.shippingFee = Number(items.shipping_free_after_discount)
                }
                
                this.delivery_fee_without_tax = Math.ceil(this.shippingFee / 1.1)
                this.delivery_tax = this.shippingFee - this.delivery_fee_without_tax
              } else {
                if (this.getModeBuyProduct === 2) {
                  if (items.regular_delivery_charges) {
                    this.shippingFee = Number(items.regular_delivery_charges)
                  } else {
                    this.shippingFee = Number(items.normal_shipping_free)
                  }
                } else {
                  this.shippingFee = Number(items.normal_shipping_free)
                  this.delivery_fee_without_tax = Math.ceil(this.shippingFee / 1.1)
                  this.delivery_tax = this.shippingFee - this.delivery_fee_without_tax
                }
              }
              if (this.cartTotalPriceTax >= this.minimum_amount) {
                this.isDisablePayorder = false
              } else {
                this.isDisablePayorder = true
              }
            }
          },
          (error: any) => {
            if (error) {
              if (error.response.status === 429) {
                this.manyRequest()
              }
              let errorData = error.response.data
              this.message = errorData.message[0].store_uuid[0]
            }
          }
        )
      }
      if (this.getModeBuyProduct === 1) {
        this.cartTotalPrice =
          this.cartTotalPriceTax +
          this.frozenPrice +
          this.coolingPrice +
          this.normalTemPrice +
          this.deliveryFee +
          this.shippingFee
      }
      if (this.getModeBuyProduct === 2) {
        this.cartTotalPrice =
          this.cartTotalPriceTax +
          this.frozenPrice +
          this.coolingPrice +
          this.normalTemPrice +
          this.deliveryFee +
          this.shippingFee
        // discount rate
        // this.cartTotalPrice =
        //   ((this.cartTotalPriceTax +
        //     this.frozenPrice +
        //     this.coolingPrice +
        //     this.normalTemPrice +
        //     this.deliveryFee +
        //     this.shippingFee) *
        //     (100 - this.discountRate)) /
        //   100
      }
    }
  }

  private reloadData(item: any) {
    this.cartTotalQuantity += Number(item.quantity)
    this.cartTotalPriceNoTax += Number(
      item.quantity * item.normal_price_excluding_tax
    )
    let objectItem = {
      temperature_control_name: item.temperature_control_name,
      temperature_control_price: item.temperature_control_price,
      temperature_control_id: item.temperature_control_id
    }
    this.temperatureControls.push(objectItem)
    // 外税8%
    if (item.tax_classification === 1) {
      this.totalCartTax8 += Number(
        item.quantity * item.normal_price_excluding_tax
      )
      this.cartTax8 = (this.totalCartTax8 * 8) / 100
    }
    // 外税10%
    if (item.tax_classification === 2) {
      this.totalCartTax10 += Number(
        item.quantity * item.normal_price_excluding_tax
      )
      this.cartTax10 = (this.totalCartTax10 * 10) / 100
    }
    // 内税8%
    if (item.tax_classification === 3) {
      this.totalInternalCartTax8 += Number(
        item.quantity * item.normal_price_excluding_tax
      )
      this.cartInternalTax8 = (this.totalInternalCartTax8 * 8) / 100
    }
    // 内税10%
    if (item.tax_classification === 4) {
      this.totalInternalCartTax10 += Number(
        item.quantity * item.normal_price_excluding_tax
      )
      this.cartInternalTax10 = (this.totalInternalCartTax10 * 10) / 100
    }
    // 非課税
    if (item.tax_classification === 5) {
      this.cartTaxFree += Number(
        item.quantity * item.normal_price_including_tax
      )
    }
    if (this.consumptionTax === 1) {
      this.cartTax8 = Math.floor(this.cartTax8)
      this.cartTax10 = Math.floor(this.cartTax10)
      this.cartInternalTax8 = Math.floor(this.cartInternalTax8)
      this.cartInternalTax10 = Math.floor(this.cartInternalTax10)
    }
    if (this.consumptionTax === 2) {
      this.cartTax8 = Math.round(this.cartTax8)
      this.cartTax10 = Math.round(this.cartTax10)
      this.cartInternalTax8 = Math.round(this.cartInternalTax8)
      this.cartInternalTax10 = Math.round(this.cartInternalTax10)
    }
    if (this.consumptionTax === 3) {
      this.cartTax8 = Math.ceil(this.cartTax8)
      this.cartTax10 = Math.ceil(this.cartTax10)
      this.cartInternalTax8 = Math.ceil(this.cartInternalTax8)
      this.cartInternalTax10 = Math.ceil(this.cartInternalTax10)
    }

    // delete object duplicate in array
    this.temperatureControls = this.temperatureControls.filter(
      (item: any, index: any) =>
        this.temperatureControls
          .map(function(e: any) {
            return e.temperature_control_name
          })
          .indexOf(item.temperature_control_name) === index
    )
    this.temperatureControls = this.temperatureControls.filter(
      (item: any) => item.temperature_control_name !== undefined
    )
    // get info temperature controls
    this.temperatureControls.forEach((element: any) => {
      // 冷凍
      if (element.temperature_control_id == 1) {
        this.frozenName = element.temperature_control_name
        this.frozenPrice = element.temperature_control_price
      }
      // 冷蔵
      if (element.temperature_control_id == 2) {
        this.coolingName = element.temperature_control_name
        this.coolingPrice = element.temperature_control_price
      }
      // 常温
      if (element.temperature_control_id == 3) {
        this.normalTemName = element.temperature_control_name
        this.normalTemPrice = element.temperature_control_price
      }
    })
  }

  public hanldeGetCartProductList() {
    if (this.getModeBuyProduct == 1) {
      this.cartProductList = this.getCart.filter((item: any) => item.mode == 1)
    }

    if (this.getModeBuyProduct == 2) {
      this.cartProductList = this.getCart.filter((item: any) => item.mode == 2)
    }
  }

  public deleteFromCart(id: number) {
    this.removeFromCart(id)
    this.cartProductList = this.getCart
  }

  public async handleChangeQuantity(productItem: any) {
    if (productItem.quantity < 1) {
      this.$toast(`商品数量は1以上で入力ください`, {
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: false,
        rtl: false
      })
      productItem.quantity = 1
    }
    await ProductService.getProductQuantity(productItem.id).then(
      (response: any) => {
        this.stockQuantity = response.data.items
      },
      (error: any) => {
        if (error.response.status === 429) {
          this.manyRequest()
        }
      }
    )
    if (productItem.quantity > this.stockQuantity) {
      if (this.stockQuantity === 0 || this.stockQuantity === null) {
        this.$toast(`この商品は在庫がありません。`, {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false
        })
      } else {
        let isPassRegex: any = /^-?\d*$/.test(productItem.quantity.toString())
        if (!isPassRegex) {
          this.$toast(`数量項目には半角数字しか入力できません。`, {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
        } else {
          this.$toast(`この商品の在庫数は` + this.stockQuantity + `個です。`, {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          })
        }
      }
      productItem.quantity = this.stockQuantity > 100 ? 100 : this.stockQuantity
    } else {
      let isPassRegex: any = /^-?\d*$/.test(productItem.quantity.toString())
      if (!isPassRegex) {
        this.$toast(`数量項目には半角数字しか入力できません。`, {
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false
        })
      }
      productItem.quantity =
        productItem.quantity > 100 ? 100 : productItem.quantity
    }

    const ProductUpdate = {
      id: productItem.id,
      quantity: Number(productItem.quantity) || 1
    }
    this.updateFromCart(ProductUpdate)
  }

  public controlTypeQuantity(event: any) {
    if (event) {
      var key = window.event ? event.keyCode : event.which
      if (
        event.keyCode === 8 ||
        (key >= 48 && key <= 57) ||
        (key >= 1776 && key <= 1785)
      ) {
        return true
      } else {
        event.preventDefault()
      }
    }
  }

  public removeAllProductFormCart() {
    this.removeAllCart()
    this.cartProductList = this.getCart
    this.hideModalConfirmDeleteAllProduct()
  }

  public showModalConfirmDeleteAllProduct() {
    // eslint-disable-next-line no-extra-semi
    ;(this.$refs['modal-confirm-delete-all-product'] as any).show()
  }

  public hideModalConfirmDeleteAllProduct() {
    // eslint-disable-next-line no-extra-semi
    ;(this.$refs['modal-confirm-delete-all-product'] as any).hide()
  }

  public checkQuantityProduct() {
    let isCorrectQuantity = true
    let modeCurrent = this.getModeBuyProduct
    this.getCart.forEach((item: any) => {
      if (
        item.mode === modeCurrent &&
        item.stock_quantity !== null &&
        item.quantity > item.stock_quantity
      ) {
        this.$toast(
          '「' +
            item.name +
            '」' +
            `この商品の在庫数は` +
            item.stock_quantity +
            `個です。`,
          {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          }
        )
        isCorrectQuantity = false
      }
      if (item.mode === modeCurrent && item.quantity > 100) {
        this.$toast(
          '「' + item.name + '」' + `一度に注文出来る最大個数は100個までです。`,
          {
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: false,
            rtl: false
          }
        )
        isCorrectQuantity = false
      }
    })

    return isCorrectQuantity
  }

  public async payOrder() {
    this.isDisableOrder = true
    if (this.flag) {
      if (!this.isDisableInfoCard) {
        //validation for credit card number here
        if (!this.checkCardNumber(this.card_number)) {
          this.isDisableOrder = false
          this.msg =
            '「カード番号」を半角数字のみで入力してください。※「 - 」ハイフンは不要'
          this.$bvModal.show('paygent-message')
        } else {
          //validate for cvv
          if (this.cvc.toString().length == 0) {
            this.isDisableOrder = false
            this.msg = '「セキュリティコード」を入力してください。'
            this.$bvModal.show('paygent-message')
          }
        }
      } else {
        this.payment()
      }
    } else {
      //validation for credit card number here
      if (!this.checkCardNumber(this.card_number)) {
        this.isDisableOrder = false
        this.msg =
          '「カード番号」を半角数字のみで入力してください。※「 - 」ハイフンは不要'
        this.$bvModal.show('paygent-message')
      } else {
        //validate for cvv
        if (this.cvc.toString().length == 0) {
          this.isDisableOrder = false
          this.msg = '「セキュリティコード」を入力してください。'
          this.$bvModal.show('paygent-message')
        } else {
          let script = document.createElement('script')
          let token_url: any = Constants.token_url
          script.setAttribute('src', token_url)
          document.head.appendChild(script)
          script.onload = () => {
            // @ts-ignore
            // eslint-disable-next-line no-undef
            let paygentToken = new PaygentToken()
            // @ts-ignore
            paygentToken.createToken(
              Constants.merchant_id, // merchant_id
              Constants.payment_token, // token
              {
                card_number: this.card_number, // 卡号
                expire_year: this.expire_year, // 有效年 19
                expire_month: this.expire_month, // 有效月 09
                cvc: this.cvc, // 安全码
                name: this.first_name + this.last_name // 用户名
              },
              this.execPurchase
            )
          }
        }
      }
    }
  }

  public execPurchase(response: any) {
    switch (response.result) {
      case '0000':
        this.paygentTokenStr = response.tokenizedCardObject.token
        this.fingerprint = response.tokenizedCardObject.fingerprint
        this.payment()
        break
      case '1100':
        this.msg = 'マーチャントID - 必須エラー'
        break
      case '1200':
        this.msg = 'トークン生成公開鍵 - 必須エラー'
        break
      case '1201':
        this.msg = 'トークン生成公開鍵 - 不正エラー'
        break
      case '1300':
        this.msg = 'カード番号 - 必須チェックエラー'
        break
      case '1301':
        this.msg =
          '「カード番号」を半角数字のみで入力してください。※「 - 」ハイフンは不要'
        break
      case '1400':
        this.msg = '有効期限(年) - 必須チェックエラー'
        break
      case '1401':
        this.msg = '有効期限(年) - 書式チェックエラー'
        break
      case '1500':
        this.msg = '有効期限(月) - 必須チェックエラー'
        break
      case '1501':
        this.msg = '有効期限(月) - 書式チェックエラー'
        break
      case '1502':
        this.msg = '有効期限(年月)が不正です。'
        break
      case '1600':
        this.msg = '「セキュリティコード」を正しく入力してください。'
        break
      case '1601':
        this.msg = '「セキュリティコード」を正しく入力してください。'
        break
      case '1700':
        this.msg = 'カード名義 - 書式チェックエラー'
        break
      case '7000':
        this.msg = '非対応のブラウザです。'
        break
      case '7001':
        this.msg = 'ペイジェントとの通信に失敗しました。'
        break
      case '8000':
        this.msg = 'システムメンテナンス中です。'
        break
      case '9000':
        this.msg = 'ペイジェント決済システム内部エラー'
        break
    }
    if (response.result != '0000') {
      this.isDisableOrder = false
      this.$bvModal.show('paygent-message')
    }
  }

  public payment() {
    if (this.cartProductList.length === 0) {
      this.isDisableOrder = false
      this.messageNoProduct = true
    } else {
      this.messageProductSoldOut = false
      this.productsSoldOut = []
      if (this.getModeBuyProduct === 1) {
        if (this.checkQuantityProduct() === true) {
          let today = moment().format('yyyy-MM-DD HH:mm')
          let productNormalOrder = this.getCart.filter(
            (item: any) => Number(item.mode) === 1
          )
          if (this.flag) {
            this.normalOrder = {
              order_date: today,
              delivery_date: localStorage.getItem('delivery_date_normal'),
              delivery_service_id: localStorage.getItem(
                'delivery_service_id_normal'
              ),
              memo: this.memo,
              products: productNormalOrder,
              is_remember: this.check_card_payment
            }
          } else {
            this.normalOrder = {
              order_date: today,
              delivery_date: localStorage.getItem('delivery_date_normal'),
              delivery_service_id: localStorage.getItem(
                'delivery_service_id_normal'
              ),
              memo: this.memo,
              products: productNormalOrder,
              payment_token: this.paygentTokenStr,
              finger_print: this.fingerprint,
              card_number: this.card_number,
              expire_year: this.expire_year,
              expire_month: this.expire_month,
              user_first_name: this.first_name,
              user_last_name: this.last_name,
              is_remember: this.check_card_payment
            }
          }

          if (
            !localStorage.getItem('delivery_date_normal') ||
            !localStorage.getItem('delivery_service_id_normal')
          ) {
            this.isDisableOrder = false
            this.messageOrderNormalValidate =
              'お届け日や配達便を選択してください。'
          } else if (this.normalOrder.products.length === 0) {
            this.isDisableOrder = false
            this.messageOrderNormalValidate = 'カートに商品がありません。'
          } else {
            OrderService.createNormalOrder(this.normalOrder).then(
              (response: any) => {
                if (response) {
                  this.isDisablePayorder = false
                  this.isDisableOrder = false
                  this.$bvModal.show('modal-order-normal-success')
                }
              },
              (error: any) => {
                if (error) {
                  this.isDisableOrder = false
                  if (Array.isArray(error.response.data.message[0])) {
                    let array = Object.keys(error.response.data.message[0])
                    array.forEach((element: any) => {
                      let index = element
                        .replace('products.', '')
                        .replace('.quantity', '')
                      if (this.cartProductList[index] !== undefined) {
                        this.productsSoldOut.push(this.cartProductList[index])
                      }
                    })
                  }

                  if (this.productsSoldOut.length !== 0) {
                    this.messageProductSoldOut = true
                  }
                  if (error.response.status === 429) {
                    this.manyRequest()
                  }
                  if (error.response.status === 440) {
                    if (
                      error.response.data.message[0] === 'DEADLINE_TIME_EXCEED'
                    ) {
                      this.messageTimeZone = true
                    } else if (
                      error.response.data.message[0] ===
                      'PAYMENT_INFORMATION_DOES_NOT_EXIST'
                    ) {
                      this.paymentInfoDoesNotExist = true
                    } else if (
                      error.response.data.message[0] ===
                      'CANNOT_GET_PAYMENT_INFORMATION'
                    ) {
                      this.$bvModal.show(
                        `paygent-message-cannot-get-payment-info`
                      )
                    } else if (
                      error.response.data.message[0] ===
                      'INVALID_PRODUCT_AMOUNT'
                    ) {
                      this.isDisablePayorder = false
                    } else if (
                      error.response.data.message[0] ===
                      'INVALID_PRODUCT_IN_CART'
                    ) {
                      this.invalid_product_in_cart = error.response.data.items
                      this.$bvModal.show(`invalid-product-in-cart`)
                    } else if (
                        error.response.data.message[0] ===
                        'EXCEED_ORDER_QUANTITY'
                    ) {
                      this.invalid_product_in_cart = error.response.data.items
                      this.$bvModal.show(`exceed-order-quantity`)
                    }
                  }
                  if (error.response.status === 500) {
                    if (
                      error.response.data.message[0] ===
                      'CANNOT_CREATE_NEW_CARD_INFORMATION'
                    ) {
                      this.$bvModal.show('paygent-message-create-credit-card')
                    } else if (
                      error.response.data.message[0] ===
                      'CANNOT_DELETE_OLD_CARD_INFO'
                    ) {
                      this.mesCannotDeleteOldCardInfo = true
                    } else if (
                      error.response.data.message[0] ===
                      'CANNOT_CREATE_PAYMENT_DETAIL'
                    ) {
                      this.$bvModal.show(`cannot-create-payment-detail`)
                    } else {
                      this.$bvModal.show(`create-order-failed`)
                    }
                  }
                }
              }
            )
          }
        }
      }
      if (this.getModeBuyProduct === 2) {
        if (this.checkQuantityProduct() === true) {
          if (this.deliveryDatePeriodic && this.delivery_service_id_normal) {
            if (this.regularDeliveryCycle) {
              if (this.flag) {
                this.periodicOrder = {
                  delivery_date: this.deliveryDatePeriodic,
                  delivery_service_id: this.delivery_service_id_normal,
                  regular_type: this.regular_type,
                  memo: this.memo,
                  products: this.cartProductList
                }
              } else {
                this.periodicOrder = {
                  delivery_date: this.deliveryDatePeriodic,
                  delivery_service_id: this.delivery_service_id_normal,
                  regular_type: this.regular_type,
                  memo: this.memo,
                  products: this.cartProductList,
                  payment_token: this.paygentTokenStr,
                  finger_print: this.fingerprint,
                  card_number: this.card_number,
                  expire_year: this.expire_year,
                  expire_month: this.expire_month,
                  user_first_name: this.first_name,
                  user_last_name: this.last_name,
                  is_remember: this.check_card_payment
                }
              }

              StoreInfoService.createOrder(this.periodicOrder).then(
                response => {
                  if (response) {
                    this.isDisablePayorder = false
                    this.isDisableOrder = false
                    this.$bvModal.show('modal-order-periodic-success')
                  }
                },
                (error: any) => {
                  if (error) {
                    this.isDisableOrder = false
                    if (Array.isArray(error.response.data.message[0])) {
                      let array = Object.keys(error.response.data.message[0])
                      array.forEach((element: any) => {
                        let index = element
                          .replace('products.', '')
                          .replace('.quantity', '')
                        if (this.cartProductList[index] !== undefined) {
                          this.productsSoldOut.push(this.cartProductList[index])
                        }
                      })
                    }

                    if (this.productsSoldOut.length !== 0) {
                      this.messageProductSoldOut = true
                    }
                    if (error.response.status === 429) {
                      this.manyRequest()
                    }
                    if (error.response.status === 440) {
                      if (
                        error.response.data.message[0] ===
                        'DEADLINE_TIME_EXCEED'
                      ) {
                        this.messageTimeZone = true
                      } else if (
                        error.response.data.message[0] ===
                        'PAYMENT_INFORMATION_DOES_NOT_EXIST'
                      ) {
                        this.paymentInfoDoesNotExist = true
                      } else if (
                        error.response.data.message[0] ===
                        'CANNOT_GET_PAYMENT_INFORMATION'
                      ) {
                        this.$bvModal.show(
                          `paygent-message-cannot-get-payment-info`
                        )
                      } else if (
                        error.response.data.message[0] ===
                        'INVALID_PRODUCT_AMOUNT'
                      ) {
                        this.isDisablePayorder = false
                      } else if (
                        error.response.data.message[0] ===
                        'INVALID_PRODUCT_IN_CART'
                      ) {
                        this.invalid_product_in_cart = error.response.data.items
                        this.$bvModal.show(`invalid-product-in-cart`)
                      } else if (
                          error.response.data.message[0] ===
                          'EXCEED_ORDER_QUANTITY'
                      ) {
                        this.invalid_product_in_cart = error.response.data.items
                        this.$bvModal.show(`exceed-order-quantity`)
                      }
                    }
                    if (error.response.status === 500) {
                      if (
                        error.response.data.message[0] ===
                        'CANNOT_CREATE_NEW_CARD_INFORMATION'
                      ) {
                        this.$bvModal.show('paygent-message-create-credit-card')
                      } else if (
                        error.response.data.message[0] ===
                        'CANNOT_DELETE_OLD_CARD_INFO'
                      ) {
                        this.mesCannotDeleteOldCardInfo = true
                      } else if (
                        error.response.data.message[0] ===
                        'CANNOT_CREATE_PAYMENT_DETAIL'
                      ) {
                        this.$bvModal.show(`cannot-create-payment-detail`)
                      } else {
                        this.$bvModal.show(`create-order-failed`)
                      }
                    }
                  }
                }
              )
            } else {
              this.messageNoRegulary = true
            }
          } else {
            this.message = true
          }
        }
      }
    }
  }

  public orderComplete() {
    this.removeAllCart()
    localStorage.removeItem('cart_product')
    localStorage.removeItem('delivery_date_normal')
    localStorage.removeItem('order_normal_delivery_service')
    localStorage.removeItem('delivery_service_id_normal')
    this.$router.push({ name: 'order-completed' })
  }

  public orderCompletePeriodic() {
    this.removeAllCart()
    localStorage.removeItem('cart_product')
    localStorage.removeItem('regular_type')
    localStorage.removeItem('delivery_date_periodic')
    localStorage.removeItem('delivery_service_id_periodic')
    this.$router.push({ name: 'subscription-setting' })
  }

  public closeModal() {
    this.$bvModal.hide('paygent-message')
  }

  public closeModalCannotGetPaymentInfo() {
    this.$bvModal.hide('paygent-message-cannot-get-payment-info')
  }

  private manyRequest() {
    localStorage.clear()
    this.$bvModal.show(`too-many-request`)
  }

  public checkCardNumber(card_number: string) {
    let isPassRegex: any = /^([0-9]{14,16})+$/.test(card_number.toString())
    return isPassRegex
  }
}
