import Const from '../constants'
import axiosApiInstance from '../interceptors'

class PaymentSettingService {
  getPaymentSetting() {
    return axiosApiInstance.get(Const.api_url + 'payment-setting')
  }
}

export default new PaymentSettingService()
